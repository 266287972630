import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '../icons/SearchIcon';
import '../css/Search.css';
import products from '../data/products.json';
import { Link, withRouter } from 'react-router-dom';
import CloseIcon from '../icons/CloseIcon.js';

class Search extends Component {
	constructor() {
		super();
		this.state = {
			expanded: false,
			value: '',
			results: [],
			terms: [],
			showResults: false
		}
	}

	filterSearch(query) {
		return this.state.terms.filter(el => {
			return el.term.toLowerCase().indexOf(query.toLowerCase()) > -1;
		});
	}

  componentWillUnmount() {
    this.routerChange();
  }

	componentDidMount() {
		this.routerChange = this.props.history.listen((location, action) => {
			this.setState({
				showResults: false,
				expanded: false,
				value: '',
			})
		});

		let terms = [];
		products.forEach(product => {
			terms.push({
				title: product.title,
				term: product.sku + ' ' + product.title + ' ' + product.tags,
				permalink: product.permalink,
				key: product.sku
			});
		});

		this.setState({
			terms: [...this.state.terms, ...terms]
		});
	}

	toggleSearch() {
		this.setState({
			expanded: !this.state.expanded,
			showResults: false
		}, () => {
			this.searchInput.focus();
		});
	}

	input(e) {
		const value = e.target.value;
		this.setState({
			value: value
		}, () => {
			if (value.length < 2) {
				this.setState({
					results: [],
					showResults: false
				});
			} else {
				const results = this.filterSearch(this.state.value);
				this.setState({
					results: results,
					showResults: true
				});
			}
		});
	}

	render() {
		const expanded = this.state.expanded;
		return (
			<Fragment>
				<div className="search">
					<form className="search-bar">
						<input autoComplete="off" onChange={(e) => this.input(e)} value={this.state.value} ref={(input) => { this.searchInput = input; }} name="search" className={`search-field ${expanded ? 'expand' : ''}`} type="search" placeholder="Search" />
						<Button
							key={expanded ? 'submit' : 'button'}
							toggleSearch={() => this.toggleSearch()}
							submit={(e) => this.submit(e)}
							expanded={expanded}
						/>
					</form>
				</div>
				{this.state.showResults ? <Results results={this.state.results} /> : null}
			</Fragment>
		);
	}
}

const Button = (props) => {
	const type = props.expanded ? 'submit' : 'button';
	return(
		<button className="search-submit" type={type} onClick={props.toggleSearch}>
			{props.expanded ? <CloseIcon width={28} height={28} color="#a4b0b8" /> : <SearchIcon width={28} height={28} color="#a4b0b8" />}
		</button>
	);
}

const Results = (props) => {
	const results = props.results.map((result) =>
		<li className="result" key={result.key}>
			<Link to={{pathname: result.permalink, state: { product: result.key}}}>{result.title}</Link>
		</li>
	);
	return(
		<div className="search-results-container">
			<ul className="search-results">
				{results}
			</ul>
		</div>
	);
}

export default withRouter(Search);
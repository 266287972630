import React, { Component } from 'react';
import Container from '../components/Container';
import SectionTitle from '../components/SectionTitle';
import {Helmet} from "react-helmet";
import '../css/Repair.css';

class Repair extends Component {
  render() {
    return (
      <div className="repair">
      	<Helmet title="Rowenta | Repair" />
				<section id="main" className="page">
				<img src="https://www.rowenta.com/medias/Header-UK-ph.png?context=bWFzdGVyfHJvb3R8MTA1NDk5N3xpbWFnZS9wbmd8aDY0L2g0Ny85NzM0MDk3MTQxNzkwLnBuZ3wxYzhjODc5NjcyYTZlYTVkMDcwYzQwNTJiMGQwNWY4NjMzNTU2YTNmYzExYjBjMjc2ZjkyMTJhY2NhMjRjZTZk" />

				<div className="whatis-row">
          <Container>
						<div className="whatis-row-inner">
								<div className="whatis-row-info">
										<img src="https://dodec.co.uk/dodec-site/wp-content/uploads/2017/02/TOOLS.png" /><h2>WHAT IS REPAIRABILITY?</h2>
										<p className="lead">Rowenta is committed to being able to repair a product for up to 10 years throughout Europe.</p>
										<p className="lead">Because protecting the environment and the fight against waste are our major concerns.</p>
								</div>
								<div className="whatis-row-video">
									<iframe width="560" height="315" src="https://www.youtube.com/embed/Uh9voVAX9fs?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allowFullScreen=""></iframe>
								</div>
						</div>
					</Container>
				</div>

				<Container>
						<div className="onYourSide-row">
							<SectionTitle topTitle="Rowenta" bottomTitle="on your side..." />
								<p className="lead">Our products are designed to be easily repaired, dismantled and reassembled.
											A very large number of parts are stored separately (40,000 references stored in France for Europe)
												in order to repair what is necessary and at the lowest cost.</p>
												<hr />
								<div className="top-content">
											<img src="https://dodec.co.uk/dodec-site/wp-content/uploads/2017/02/PIEZAS.png" />
											<p>Rowenta engineers are continually proposing modifications based on operational feedback to ensure that the new generations of products are even easier to repair. Each new reference of finished product is certified with the label "Repairable Product 10 years".</p>
											<img src="https://dodec.co.uk/dodec-site/wp-content/uploads/2017/02/COMPUTER.png" />
								</div>
								<hr className="hr"/>
								<div className="bottom-content">
									<h2>A FEW NUMBERS</h2><br />
										<div className="few-numbers">
												<img src="https://dodec.co.uk/dodec-site/wp-content/uploads/2017/02/10.png" alt="10 years repairable" />
												<div className="few-numbers-right">
													<p className="lead"><span className="title">10 YEARS</span><br />Rowenta is committed to keeping for 10 years on average after purchase, all technical parts for virtually all products marketed since 2012*.</p>
												</div>
											</div><br />
											<div className="few-numbers">
												<img src="https://dodec.co.uk/dodec-site/wp-content/uploads/2017/02/almacen.png" alt="spare parts stock" />
												<div className="few-numbers-right">
													<p className="lead"><span className="title">5.7 MILLION PARTS OVER 15,000 m2</span><br />
													To ensure immediate availability of spare parts, half the stock has been stored in anticipation for the coming years, for products that are no longer manufactured.</p>
												</div>
											</div><br />
											<div className="few-numbers">
												<img src="https://dodec.co.uk/dodec-site/wp-content/uploads/2017/02/POUND.png" />
												<div className="few-numbers-right">
													<p className="lead"><span className="title">COST OF THE REPAIR LESS EXPENSIVE</span><br />
													The cost of repair is often less expensive than replacing a product, particularly due to the lowering of prices of spare parts (30% on average since 2012). A repair does not alter in any way the quality of the product, and limits the environmental impact of waste.</p><br />
												</div>

											</div>
											<p className="notes">
												<span className="small">
													<br />
													<small style={{fontSize: 14}}>
														* 99% of Rowenta products marketed in 2015 in Europe are repairable (of which 32%, with the exception of a maximum of one or two unavailable parts, repairable at a "reasonable" cost)
													</small>
												</span>
											</p>
								</div>
						</div>

						<div className="nearYou-row">
								<div className="nearYou-row-location">
										<p><span>6,500</span><br />
										professional<br />
										repairers
										</p>
								</div>
								<div className="nearYou-row-info">
									<SectionTitle topTitle="Rowenta" bottomTitle="near you..." />
										<p className="lead">The best service for your products is provided by professionals trained by Rowenta, who will always look for a solution to help extend the use of a product.
										Today, more than 6,500 authorised professional repairers are working with you to ensure the sustainability of the products throughout the world, whatever the country of purchase or use.</p>
										<p className="lead red">Click on the button to find your nearest authorised repairer. Remember to first use our online help resources to see if a solution already exist among the many sources online. </p><br />
										<a style={{display: 'block'}} href="/repairs" className="btn btn-primary btn-big">FIND A REPAIRER</a>
								</div>
						</div>
						<div className="last-row">
							<div className="oneClick">
									<h2>REPAIRABILITY <br />
									WITH  ONE CLICK</h2>

									<div className="iframe-container-bot">
										<iframe width="560" height="315" src="https://www.youtube.com/embed/MsTRnDjCpEE?rel=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
									</div>

								<p className="lead">Once the order is placed, the replacement parts for Rowenta products are delivered very quickly to the repairer.
									This allows a product failure to be repaired for many years even after the guarantee period.
									You may also order some the of parts directly, to repair your products yourself using video tutorials.</p>
							</div>
							<div className="_3d">
									<h2>3D PRINTING TO FIGHT <br />
									AGAINST PLANNED OBSOLESCENCE</h2>

									<div className="iframe-container-bot">
										<iframe width="560" height="315" src="https://www.youtube.com/embed/jhFLSq4VmNE?rel=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
									</div>

									<p className="lead">To ensure a lifetime supply of certain plastic parts, Rowenta has been testing since May 2016 the first products repaired with 3D printed spare parts.</p>

									<div className="testimonial">
											<img src="https://dodec.co.uk/dodec-site/wp-content/uploads/2017/02/testimonial.png" alt="10 years repairable" />

												<span className="lead">
												“We now realise that these are products that deserve a long life”
												<br />
												<span className="small" style={{display: 'block', marginTop: 10, marginBottom: 10}}><b>Alain Pautrot</b> - VP Consumer Satisfaction


													<div id="myModal" className="modal">

														<div className="modal-content">

															<div>
																<div className="videoWrapper">
																	<iframe src="https://www.youtube.com/embed/8RjGu8zOFzs?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allowFullScreen=""></iframe>
																</div>
															</div>
														</div>
													</div>
												</span>
												<a href="https://www.youtube.com/watch?v=8RjGu8zOFzs?rel=0&amp;controls=0&amp;showinfo=0" target="_blank" className="btn watch-test">Watch the interview</a>
												</span>

									</div>
							</div>
						</div>
					</Container>
				</section>
      </div>
    );
  }
}

export default Repair;
import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Container from '../components/Container';
import {Helmet} from "react-helmet";

import '../css/Cookies.css';

const Cookies = () => {
  return (
    <div className="Cookies">
    <Helmet title="Rowenta | Cookies policy"  ></Helmet>
    <Container>
				<div className="CookiesRowenta">
					<h2 className="CookiesRowentatitle">Cookies Policy</h2>
					<ul>
						<li><a href="#RowentaCookies1">What is a cookie?</a></li>
						<li><a href="#RowentaCookies2">What types of cookies are used on the Web Site?</a></li>
					</ul>
					<p className="CookiesRowentaIntroLead"><strong>Applicable as of 05/01/2018</strong></p>
					<p>The rowenta.co.uk web site (hereinafter the “Web Site”) uses cookies. Please read carefully our Cookies Policy below.</p>

					<p className="CookiesRowentaIntroLead" id="RowentaCookies1">What is a cookie?</p>
					<p>A cookie is a text file placed and/or read by the browser of an Internet user on the hard disk of his terminal (e.g. computer, Smartphone, etc.), to which a domain name is associated. Cookies are used to store information and some of them are essential for the proper operation of a web site and of the software solutions contained on it.</p>
					<p>There are two types of cookies:</p>
					<ul>
						<li>“First party” cookies which can only be created and read by the domain name visited</li>
						<li>“Third party” cookies which can be created and read by a domain other than that which was visited</li>
					</ul>

					<p className="CookiesRowentaIntroLead" id="RowentaCookies2">What types of cookies are used on the Web Site?</p>
					<p><strong>Cookies essential for the proper functioning of the Web Site</strong></p>
					<p>These cookies enable the proper operation of the main functionalities of the Web Site. </p>
					<p>They allow us notably:</p>
					<ul>
						<li>To enable you to authenticate on the Web Site</li>
						<li>To recognise you when you return to the Web Site</li>
					</ul>
					<p>If you refuse the placing of these cookies, this may significantly deteriorate your user experience during your browsing of the Web Site.</p>
					<p><strong>Personalisation cookies</strong></p>
					<p>We use personalisation solutions to improve the quality of the information and the services that we provide on the Web Site in order meet your expectations at best. The software solutions that enable this personalisation systematically place cookies that are essential for their proper operation</p>
					<p><strong>Analytics cookies</strong></p>
					<p>We use analytics solutions to measure the origin, volume and behaviour of all of the visitors on the Web Site in order to improve your user experience. The analytics software solutions systematically place cookies that are vital for their proper operation.</p>
					<p><strong>Advertising cookies</strong></p>
					<p>We work with third party companies which display ads over the Internet on our behalf. Advertising cookies enable these companies to collect information concerning your visits on the Web Site in order to optimise our advertising campaigns and measure their impact.</p>
					<p><strong>Social networking cookies</strong></p>
					<p>We offer functionalities for sharing the content of the Web Site on social networks, to enable you to share content on your favourite social network. Software solutions that are used for sharing on social networks systematically place cookies that are vital for their proper operation.</p>

				</div>
			</Container>
    </div>
  );
};

export default Cookies;









import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from "react-router-dom";
import { Helmet } from "react-helmet";

import ScrollToTop from "./components/ScrollToTop";

import Container from "./components/Container";

import Header from "./components/Header";
import ConsumerServices from "./components/ConsumerServices";
import Footer from "./components/Footer";
import Category from "./pages/Category";
import SubCategory from "./pages/SubCategory";

import Home from "./pages/Home";
import About from "./pages/About";
import Guarantee from "./pages/Guarantee";
import NotFound from "./pages/NotFound";
import Product from "./pages/Product";
import Repair from "./pages/Repair";
import RepairCentres from "./pages/RepairCentres";
import Cookies from "./pages/Cookies";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import ContactUs from "./pages/ContactUs";
import WTB from "./pages/WTB";
import ProductRegistration from "./pages/ProductRegistration";

import "./css/App.css";
import steamironsData from "./data/sc-steam-irons.json";
import steamgensData from "./data/sc-steam-gens.json";
import garmentsteamersData from "./data/sc-garment-steamers.json";
import airPurifiersData from "./data/sc-air-purifiers.json";
import fansData from "./data/sc-fans.json";
import humidifiersData from "./data/sc-humidifiers.json";
import heatersData from "./data/sc-heaters.json";

import linenCareBanner from "./images/linen-care-banner.jpg";
import homeComfortBanner from "./images/banner-home-comfort.jpg";
import Accessories from "./pages/Accessories";
import Instructions from "./pages/Instructions";

class App extends Component {
	render() {
		return (
			<Router>
				<ScrollToTop>
					<div className="App">
						<Helmet title="Rowenta UK" />

						<Header />

						<Switch>
							<Route path="/" exact component={Home} />
							<Route path="/about" component={About} />
							<Route path="/guarantee" component={Guarantee} />
							<Route path="/repairability" component={Repair} />
							<Route path="/repairs" component={RepairCentres} />
							<Route path="/cookies-policy" component={Cookies} />
							<Route path="/terms-and-conditions" component={Terms} />
							<Route path="/privacy-policy" component={Privacy} />
							<Route path="/where-to-buy" component={WTB} />
							<Route path="/accessories" component={Accessories} />
							<Route path="/instructions-for-use" component={Instructions} />

							<Route
								exact
								path="/linen-care"
								render={() => (
									<Category
										location={'linen-care'}
										bg={linenCareBanner}
										title="Linen Care"
										desc="The ultimate solution for hard water"
										prodLink="/linen-care/steam-irons/DW9230"
									/>
								)}
							/>

							<Route
								exact
								path="/linen-care/steam-irons"
								render={() => <SubCategory data={steamironsData} />}
							/>
							<Route
								path="/linen-care/steam-irons/:id"
								component={Product}
								location={this.props.location}
							/>

							<Route
								exact
								path="/linen-care/steam-gens"
								render={() => <SubCategory data={steamgensData} />}
							/>
							<Route
								path="/linen-care/steam-gens/:id"
								component={Product}
								location={this.props.location}
							/>

							<Route
								exact
								path="/linen-care/garment-steamers"
								render={() => <SubCategory data={garmentsteamersData} />}
							/>
							<Route
								path="/linen-care/garment-steamers/:id"
								component={Product}
								location={this.props.location}
							/>

							<Route
								exact
								path="/home-comfort"
								render={() => (
									<Category
										location={'home-comfort'}
										bg={homeComfortBanner}
										title="Home Comfort"
										desc="Breathe easy with Pure Air"
										prodLink="/home-comfort/air-purifiers/PU3030F0"
									/>
								)}
							/>

							<Route
								exact
								path="/home-comfort/air-purifiers"
								render={() => <SubCategory data={airPurifiersData} />}
							/>
							<Route
								path="/home-comfort/air-purifiers/:id"
								component={Product}
								location={this.props.location}
							/>

							<Route
								exact
								path="/home-comfort/humidifiers"
								render={() => <SubCategory data={humidifiersData} />}
							/>
							<Route
								path="/home-comfort/humidifiers/:id"
								component={Product}
								location={this.props.location}
							/>

							<Route
								exact
								path="/home-comfort/fans"
								render={() => <SubCategory data={fansData} />}
							/>
							<Route
								path="/home-comfort/fans/:id"
								component={Product}
								location={this.props.location}
							/>

							<Route
								exact
								path="/home-comfort/heaters"
								render={() => <SubCategory data={heatersData} />}
							/>
							<Route
								path="/home-comfort/heaters/:id"
								component={Product}
								location={this.props.location}
							/>

							<Route
								path="/consumer-services/contact-us"
								component={ContactUs}
							/>

							<Route
								path="/registermyproduct"
								component={ProductRegistration}
							/>

							<Route component={NotFound} />
						</Switch>

						<ConsumerServices />
						<Footer />
					</div>
				</ScrollToTop>
			</Router>
		);
	}
}

export default App;

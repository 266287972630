import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import '../css/SectionTitle.css';
import { Link } from 'react-router-dom';

class SectionTitle extends Component {
	render() {
		if (!this.props.href) {
			return(
				<Fragment>
					<Title topTitle={this.props.topTitle} bottomTitle={this.props.bottomTitle} color={this.props.color}/>
				</Fragment>
			);
		} else {
			return(
				<Link to={this.props.href}>
					<Title topTitle={this.props.topTitle} bottomTitle={this.props.bottomTitle} color={this.props.color}/>
				</Link>
			);
		}
	}
}

class Title extends Component {
	render() {
		return (
			<h2 className="section-title">
				<span style={{color: this.props.color}} className="top-title"><i>{this.props.topTitle}</i></span>
				<br />
				<span style={{color: this.props.color}} className="bottom-title">{this.props.bottomTitle}</span>
			</h2>
		)
	}
}

export default SectionTitle;
import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Container from '../components/Container';
import {Helmet} from "react-helmet";


import '../css/ContactUs.css';


const ContactUs = () => {
	return (
	    <div className="Contact">
	    	<Helmet title="Rowenta | Contact us"  ></Helmet>
	    	<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossOrigin="anonymous" />	    	
	    	<Breadcrumb />
	    	<div className="ContactUsBanner">
	    		<h1>Contact us</h1>
	    	</div>
		 	<Container>		 		
			 	<div class="iframe-wrapper">
			        <iframe src="https://customer-services.groupeseb.co.uk/rowenta" width="100%" height="800" frameborder="0"> </iframe>
		        </div>		        
			</Container>
	    </div>	
	);
};

export default ContactUs;

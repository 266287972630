import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Container from '../components/Container';
import {Helmet} from "react-helmet";


import '../css/Terms.css';

const Terms = () => {
  return (
    <div className="Terms">
    	<Helmet title="Rowenta | T & C"  ></Helmet>
			<Container>
				<div className="TermsRowenta">
					<h2 className="TermsRowentatitle">Legal terms</h2>
					<p className="TermsRowentaIntroLead">Legal Terms and Conditions of the Rowenta website</p>
					<p>By entering this site you acknowledge and agree to all of the terms and conditions set forth below.</p>
					<ol>
						<li>The material on this site is designed for and may be downloaded only for your non-commercial personal use. In consideration of this authorisation, you agree that any copy of this site, or any material from this site, which you make shall retain all copyright, trademark or other proprietary notices contained herein.<br />
						Any unauthorised use of the materials may subject the viewer to penalties or damages, including but not limited to those related to violation of trademarks, copyrights, privacy and publicity rights.</li>
						<li>All trademarks, service marks and logos found on this site are the registered or unregistered Marks of Rowenta and its sister companies. Nothing contained in this site should be construed as granting, by implication, estoppel or otherwise, any license or right to use any patent or Mark displayed, without written permission by owner of the Mark.</li>
						<li>THE INFORMATION ON THIS SITE, IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS OR PARTICULAR PURPOSE OR NONINFRINGEMENT.</li>
						<li>Rowenta makes no warranties or representations as to the accuracy or completeness of the information. Rowenta assumes no liability or responsibility for errors or omissions in contents, or to update or correct information on the site.</li>
						<li>Rowenta reserves the right to make changes at any time, without notice, in information, materials, equipment, specifications, models and availability.</li>
						<li>Rowenta IS NOT LIABLE FOR ANY DIRECT, INCIDENTAL, CONSEQUENTIAL, INDIRECT, OR PUNITIVE DAMAGES ARISING OUT OF YOUR ACCESS TO, OR USE OF, THIS SITE OR ANY LINKED SITE.</li>
						<li>This site may be linked to other sites, which are not maintained by Rowenta. Rowenta is not responsible for the content of those linked sites, and the linking does not imply endorsement by Rowenta of those sites. Rowenta has no control over the content on such linked sites and is not liable for any consequence whatsoever resulting from your used of the linked site.</li>
					</ol>
					<p>Should any viewer respond with information, including feedback data such as questions, comments, suggestions, or the like regarding the content of any document, such information shall he deemed non-confidential, non-proprietary and the property of Rowenta. Rowenta shall have no obligation of any kind whatsoever with respect to such information and shall be free to reproduce, use, disclose and distribute the information to others without limitation. Further, Rowenta shall be free to use any ideas, concepts, know-how, or techniques contained in such information for any purpose whatsoever, including but not limited to developing, manufacturing, and marketing products incorporating such information.</p>

					<p>We inform you that the secrecy of correspondence transmitted over the Internet is not guaranteed. The information that you transmit is destined exclusively to Groupe SEB and its subsidiaries. You have the right to the access and rectification of this information from Groupe Seb France. (Loi Informatique et Liberté du 6 janvier 1978).</p>

					<p><strong>
					ROWENTA CONSUMER SERVICE<br />

					GROUPE SEB UK Ltd.<br />
					Riverside House, Riverside Walk<br />
					Windsor SL4 1NA<br />
					UNITED KINGDOM<br />
					Tél. : (44) 0345 602 1454<br />
					Registered in England No. 1450244<br />
					</strong></p>
				</div>
			</Container>

    </div>
  );
};

export default Terms;









import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import Container from '../components/Container';
import { iframeResizer } from 'iframe-resizer';

class ProductRegistration extends Component {
	componentDidMount() {
		iframeResizer({ log: false, checkOrigin: false, heightCalculationMethod: "lowestElement" }, '#productRegistrationApp');
		if (window.location.search !== "") {
			this.productRegistrationLoad("https://prod-reg-web-production.herokuapp.com");
		}
	}

	getQuery() {
		var url = window.location.search;
		var qs = url.substring(url.indexOf('?') + 1).split('&');
		for (var i = 0, result = {}; i < qs.length; i++) {
			qs[i] = qs[i].split('=');
			result[qs[i][0]] = decodeURIComponent(qs[i][1]);
		}
		return result;
	}

	productRegistrationLoad(appUrl) {
		var target = this.getQuery().page ? this.getQuery().page : "";
		var token = this.getQuery().token ? "&token=" + this.getQuery().token : "";
	
		document.getElementById("productRegistrationApp").src = appUrl + target + "?brand=Rowenta" + token;
	}

	render() {
		return (
			<div className="product-registration">
				<Helmet title="Rowenta | Product Registration" />
				<Container>
					<iframe className="frame" style={{marginBottom: 25}} id="productRegistrationApp" src="https://prod-reg-web-production.herokuapp.com?brand=Rowenta" frameBorder="0" height="350" width="100%" scrolling="yes" />
				</Container>
			</div>
		);
	}
}

export default ProductRegistration;
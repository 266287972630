import React from 'react';
import '../css/Hero.css';
import femme from '../images/femme.jpg';

import Container from './Container';

const Hero = () => {
	return (
		<section className="Hero">
			<Container>
				<div className="HeroInner">
					<h1 className="h1">ENJOY TECHNOLOGY</h1>
					<h2><i>Choose a category</i></h2>
					{window.innerWidth > 768 ? (
						<div className="video-wrapper">
							<Video id="uN68dKmukgc" />
						</div>
					) : null}
				</div>
			</Container>
		</section>
	);
}

const Video = (props) => {
  return(
		<iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${props.id}?autoplay=1&modestbranding=1&controls=0&showinfo=0&rel=0&loop=1&playlist=${props.id}&mute=1`} />
	);
}

export default Hero;
